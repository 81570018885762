import { template as template_db376a56f101439cb36a9a393d5e2a70 } from "@ember/template-compiler";
const SidebarSectionMessage = template_db376a56f101439cb36a9a393d5e2a70(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
