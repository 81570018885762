import { template as template_96fc07e5cdcc48f0a9f746a3f6bb2bd8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_96fc07e5cdcc48f0a9f746a3f6bb2bd8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
