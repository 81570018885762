import { template as template_ed2b66df2ee249a7a28282682960a360 } from "@ember/template-compiler";
const FKLabel = template_ed2b66df2ee249a7a28282682960a360(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
